.form-control.input-search {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #544848d6;
    ;
}

/* .input-search {
    height: 40px;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    padding: 0 8px;
} */

/* .table {
    border: 1px solid black;
} */
.filtering-table thead th {
    border-bottom: 1px solid black !important;

}

.filtering-table thead th:first-child {
    width: 70px;

}

tr:nth-child(even) {
    background-color: #f9f9f9;
}

.table tfoot tr {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: #EEEEEE;
}

.filter-pagination {
    display: inline-block;
    background: #f9f9f9;
    border-radius: 10px;
    padding: 5px 5px;
}

.filter-pagination .previous-button,
.filter-pagination .next-button {
    font-size: 16px;
    border: 0 !important;
    width: auto;
    padding: 14px 20px;
    margin: 0 5px;
    border-radius: 10px;
    display: inline-block;
    background-color: #fff;
    color: #7e7e7e;
}

.filter-pagination button:hover {
    background: #23b9b6;
    color: #fff;
}

.filter-pagination button[disabled] {
    opacity: 0.5;
    cursor: default;
}

.filter-pagination button[disabled]:hover {
    background: #2f363e;
    opacity: 0.5;
    color: #fff;
}

.table-index input[type=number] {
    background: #f9f9f9 !important;
    color: #7e7e7e;
    width: 70px;
    border: 0;
    background-color: #f8f8f8;
    padding: 6px 0 6px 10px;
}

@media only screen and (max-width: 575px) {

    .filter-pagination .previous-button,
    .filter-pagination .next-button {
        padding: 8px 10px;
        font-size: 14px;
    }
}